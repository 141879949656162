/* 
// TABLE OF CONTENTS //
body
	**** HEADER ****
	header - Header of page
		.navbar - Main navbar/menu
			a.logo - Logo of the website
			ul#menu.main-menu - The main menu of the website
				li a - Menu item
	**** SLIDER ****
	.slider - Main slider using the flexslider structure
		.flexslider
			..
			flexslider structure
			..
	**** ALL SECTION HAVE THE SAME STRUCTURE ****
	**** WHO WE ARE SECTION ****
	.who-we-are - Container of section
		h2.section-title - Section title
		p.body-text - All the body text that has the same styles uses this class
	**** SERVICES SECTION ****
	.services - Container of section
		.serv - The block where service informations are
			.icon-bg - IS the round background where we put the icon for the service
			.title - Title of service
	**** FUN FACTS SECTION ****
	.fun-facts - Container of section
		.fact - Container of one fact
			.number - Fun number for the fact
	**** WORK SECTION ****
	.work - Container of section
		- Structure used to add hover to images
		.img-wrap
			img
			.img-hover - Add hover color here and style
	**** TESTIMONIALS SECTION ****
	.testimonials-section - Container of section
		.flexslider2 - Using flexslider for testimonials (The same structure for every flexslider)
			img - Image of client
			p.testimonial-txt - Text
			p.client - Client name class
	**** NEWSLETTER SECTION ****
	.newsletter - Container of section
	**** CONTACT US SECTION ****
	.contact-section - Container of section
		.option - Stands for one of the contact options (phone, email, address)
	**** FOOTER ****
		footer - Footer container
*/

// Css base
@import 'core/vars';
@import 'core/reset';
@import 'core/grid';



// Site
@import 'site/site';
@import 'site/home';
 

// Vendors -- PLUGINS
@import 'vendors/slicknav';
@import 'vendors/flexslider';
@import 'vendors/magnific-popup';
@import 'vendors/animate';
@import 'vendors/ionicons';
 
