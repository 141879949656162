/*!
 * SlickNav Responsive Mobile Menu v1.0.7
 * (c) 2016 Josh Cope
 * licensed under MIT
 */

 /* HAMBURGER BUTTON POSITION (LEFT OR RIGHT) */
.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer; 
}
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; 
  }

.slicknav_menu {
  *zoom: 1; 
}
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; 
  }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; 
  }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; 
    }
  /* HAMBURGER BACKGROUND MARGIN - CAN EDIT MARGIN TOP*/
  .slicknav_menu .slicknav_no-text {
    margin: 0; 
  }
  /* HAMBURGER ICON LINES */
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 30px;
    height: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    /*
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); */
  }
  .slicknav_menu:before {
    content: "";
    display: table; 
  }
  .slicknav_menu:after {
    content: "";
    display: table;
    clear: both; 
  }

.slicknav_nav {
  clear: both; 
}
  .slicknav_nav ul {
    display: block; 
  }
  .slicknav_nav li {
    display: block; 
  }
  /* LITTLE ARROW */
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; 
  }
  .slicknav_nav .slicknav_item {
    cursor: pointer; 
  }
    .slicknav_nav .slicknav_item a {
      display: inline; 
    }
  .slicknav_nav .slicknav_row {
    display: block; 
  }
  .slicknav_nav a {
    display: block; 
  }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; 
  }

.slicknav_brand {
  float: left; 
}

/* MENU BACKGROUND COLOR */
.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: #1e1e1e;
  // padding: 5px 10px 0 0; 
}
  .slicknav_menu * {
    box-sizing: border-box; 
  }
  /* CAN'T MODIFY */
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 3px #000; 
  }
  /* HAMBURGER LINES COLOR - SPACING BETWEEN LINES */
  .slicknav_menu .slicknav_icon-bar {
    background-color: #fff; 
    margin-bottom: 0;
  }

/* HAMBURGER BUTTON BACKGROUND */
.slicknav_btn {
  margin: 10px 15px 6px;
  text-decoration: none;
  /*text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);*/
  /*-webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;*/
  background-color: transparent; 
  width: 40px;
  height: 40px;
  outline: none;
}
/* SPACING OF THE WHOLE MENU */
.slicknav_nav {
  background-color: #fbfbfb;

  color: #fff;
  // margin: 20px;
  padding: 0;
  font-size: 18px;
  list-style: none;
  overflow: hidden; 
  text-align: center;
  font-weight: 400;

  font-family: $fontTwo;
}
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0 0 0 20px; 
  }
  /* HOVER BG PADDING/MARGIN */
  .slicknav_nav .slicknav_row {
    padding: 5px 10px;
    margin: 10px 0px; 
  }
    /* DROPDOWN LI HOVER COLOR */
    .slicknav_nav .slicknav_row:hover {
      /*-webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;*/
      background: transparent;
      color: #000; 
    }
  /* LINKS COLOR / PADDING*/
  .slicknav_nav a {
    padding: 4px 0px;
    margin: 10px 0px;
    text-decoration: none;
    color: #000; 

  }
  /* LINKS HOVER COLOR & BACKGROUND COLOR */
    .slicknav_nav a:hover {
      /*-webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;*/
      background: transparent;            /* LINK BACKGROUND COLOR */
      color: #ccc; 
    }
  /* CAN'T MODIFY */
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; 
  }
  /* MARGIN/PADDING OF DROP-NAV ITEMS */
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; 
  }
  /* CAN'T MODIFY */
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; 
  }

/* CAN'T MODIFY */
.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; 
}


/* MY STYLES */
/* SUB LIST LINKS */
.drop-nav a {
  font-size: 16px;
  display: block;
  padding: 2px;
}

span.slicknav_arrow {
  display: none;
}
/* BG FOR LINKS */
ul.slicknav_nav {
  background-color: #212121;
  padding: 20px 0;
}

ul.slicknav_nav li a {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  padding-bottom: 18px;
  outline: none;
  font-family: $fontTwo;
  &:hover {
    color: #000;
    transition: .3s;
  }
}
/* BG ALL */
.slicknav_nav {
  background-color: #212121;
}
ul.slicknav_nav {
  margin: 0;
  padding: 0;
}
/* ALL LINKS STYLE */
ul.slicknav_nav li a {
  color: #fff;
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding: 16px 0;
  &:hover {
    color: $blueColor;
  }
}
/* BORDER FOR LINKS */
ul.slicknav_nav li {
  border-bottom: 2px solid #111111;
  padding: 6px 0;
}
/* DROP-NAV BG STYLE */
.slicknav_nav ul {
  background-color: #222222;
  margin: 0;
}
/* DROP-NAV ITEM STYLE */
// .slicknav_nav .slicknav_row {
//   margin: 0;
//   padding: 0;
//   padding: 16px 0;
//   position: relative;
//   &:hover {
//     color: #fff;
//   }
//   &:after {
//       content: "+";
//       position: absolute;
//       right: 20px;
//       top: 10px;
//       font-size: 24px;
//     }
// }
/* HAMBURGER LINES */
.slicknav_menu .slicknav_icon-bar {
  height: 2px;
  margin-bottom: 4px;
}
/* HAMBURGER BG */
.slicknav_btn {
  margin: 14px 40px 6px;
}




.slicknav_menu {
  display: none;
}

@media (max-width: 768px) {
  .slicknav_menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
}

