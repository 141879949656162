/* OVERALL */
a {
	text-decoration: none;
}

img {
	vertical-align: middle;
}

body {
	font-family: $fontOne;
}

.section-title {
	font-family: $fontOne;
	font-size: $secTitle;
	font-weight: 700;
	color: #333333;
	margin-bottom: 30px;
	text-transform: uppercase;
	letter-spacing: 2px;
	@media(max-width: 480px) {
		font-size: 26px;
	}
}

.body-text {
	font-family: $fontOne;
	font-size: $textSize;
	color: #5b5b5b;
	line-height: 24px;
}

/* BUTTON */
.btn {
	display: block;
	background-color: $blueColor;
	padding: 14px 0;
	width: 200px;
	text-align: center;
	max-width: 100%;
	text-transform: uppercase;
	font-size: 14px;
	color: #fff;
	font-family: $fontTwo;
	letter-spacing: 1px;
	transition: all 0.5s ease;
}

