// Fonts
$fontOne	: 'Raleway', sans-serif;
$fontTwo	: 'Lato', sans-serif;

// FONT COLORS
$blueColor	: #0088a3;

// BACKROUND COLORS


// FONT SIZES
$secTitle	: 35px;
$textSize	: 15px;


// Media Queries
$mediaRe : 320px;
$mediaXs : 480px;
$mediaSm : 768px;
$mediaMd : 960px;
$mediaLa : 1200px;

 