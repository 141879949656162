/* NAVBAR */
.navbar {
	background-color: transparent;
	padding: 20px 0;
	position: fixed;
	z-index: 999;
	width: 100%;
	// top: 48px;
	transition: all .2s;
	.logo {
		font-family: $fontOne;
		font-size: 22px;
		font-weight: 700;
		color: #fff;
		text-transform: uppercase;
		letter-spacing: 7px;
		@media(max-width: 768px) {
			position: absolute;
			top: -32px;
		}
	}
	.main-menu {
		float: right;
		font-family: $fontTwo;
		// margin-top: 10px;
		> li {
			float: left;
			margin-left: 40px;
			text-transform: uppercase;
			&:first-child {
				margin-left: 0;
			}
			a {
				color: #fff;
				font-size: 12px;
				font-weight: 700;
				letter-spacing: 0.5px;
				&:hover {
					color: $blueColor;
				}
			}
			@media(max-width: 980px) {
				margin-left: 20px;
			}
			@media(max-width: 840px) {
				margin-left: 14px;
			}
		}
		@media(max-width: 768px) {
			display: none;
		}
	}
}

/* SLIDER */
.slider {
	overflow: hidden;
	li {
		position: relative;
		img {
			width: 100%;
		}
	}
	.content-wraper {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		top: 0;
		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			width: 100%;
			p.small-text {
				font-size: 14px;
				color: #fff;
				font-family: $fontOne;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: 8px;
				margin-bottom: 50px;
				@media(max-width: 768px) {
					margin-bottom: 30px;
				}
				@media(max-width: 470px) {
					letter-spacing: 3px;
					margin-bottom: 20px;
				}
				@media(max-width: 370px) {
					font-size: 12px;
					letter-spacing: 1px;
				}
			}
			p.big-text {
				font-size: 90px;
				color: #fff;
				font-family: 'Oswald', sans-serif;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1px;
				@media(max-width: 930px) {
					font-size: 50px;
				}
				@media(max-width: 1080px) {
					font-size: 80px;
				}
				@media(max-width: 768px) {
					font-size: 60px;
				}
				@media(max-width: 470px) {
					font-size: 40px;
				}
				@media(max-width: 370px) {
					font-size: 30px;
				}
			}
			.btn {
				color: #fff;
				background-color: transparent;
				border: 1px solid #fff;
				margin: auto;
				&:hover {
					border-radius: 30px;
					transition: all .3s;
					background-color: #fff;
					color: #000;
				}
			}
		}
	}
	@media(max-width: 768px) {
		margin-top: 49px;
	}
}

/* WHO WE ARE SECTION */
.who-we-are {
	background-color: #fff;
	padding: 100px 0 0;
	.body-text {
		margin-bottom: 26px;
	}
	.btn {
		&:hover {
			background-color: #333333;
		}
	}
	@media(max-width: 1000px) {
		[class*='col-'] {
			width: 100%;
			.btn {
				margin-bottom: 50px;
			}
			img {
				display: block;
				margin: auto;
			}
		}
	}
	@media(max-width: 768px) {
		.section-title, .body-text{
			text-align: center;
		}
		.btn {
			margin: auto;
			margin-bottom: 50px;
		}
	}
	@media(max-width: 768px) {
		padding: 100px 30px 0;
	}
}

/* SERVICES SECTION */
.services {
	background-color: #eee;
	padding: 100px 0;
	.section-title {
		text-align: center;
		margin-bottom: 110px;
	}
	.serv {
		background-color: #f7f7f7;
		position: relative;
		padding: 80px 24px 36px;
		border: 1px solid #d8d8d8;
		text-align: center;
		transition: all 0.7s ease;
		max-width: 300px;
		margin: auto;
		&:hover {
			box-shadow: 1px 0 8px rgba(0,0,0,.12);
			transition: all 0.7s;
			.icon-bg {
				background-color: #343434;
				transition: all 0.7s;
			}
		}
		.icon-bg {
			position: absolute;
			top: -55px;
			left: 50%;
			transform: translate(-50%,0);
			background-color: #0088a3;
			border-radius: 50%;
			width: 100px;
			height: 100px;
			padding-top: 22px;
			transition: all 0.7s ease;
			i {
				font-size: 50px;
				color: #fff;
			}
		}
		.title {
			font-size: 16px;
			color: $blueColor;
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 18px;
		}
	}
	@media(max-width: 950px) {
		[class*='col-'] {
			width: 50%;
			.serv {
				margin-bottom: 100px;
			}
		}
	}
	@media(max-width: 540px) {
		[class*='col-'] {
			width: 100%;
		}
	}
	@media(max-width: 768px) {
		padding: 100px 30px 0;
	}
}

/* FUN FACTS */
.fun-facts {
	background: linear-gradient(rgba(0,136,163,.8), rgba(0,136,163,.8)),
				url("../images/social-bg.jpg") no-repeat;
	background-size: cover;
	padding: 80px 0;
	.fact {
		text-align: center;
		color: #fff;
		font-size: 15px;
		.number {
			font-family: $fontTwo;
			font-size: 40px;
			margin-bottom: 12px;
			.fact-nbr {
				font-weight: 700;
			}
		}
	}
	@media(max-width: 768px) {
		padding: 80px 0 30px;
		[class*='col-'] {
			width: 50%;
		}
		.fact {
			margin-bottom: 50px;
		}
	}
	@media(max-width: 480px) {
		[class*='col-'] {
			width: 100%;
		}
	}
}

/* OUR WORK/PORTFOLIO SECTION */
.work {
	background-color: #fff;
	padding: 100px 0;
	.section-title {
		text-align: center;
		margin-bottom: 80px;
	}
	.container-sm {
		width: 80%;
		margin: auto;
		.img-wrap {
			position: relative;
			max-width: 298px;
			display: block;
			margin-bottom: 30px;
			outline: none;
			&:hover .img-hover {
				background-color: rgba(0,0,0,.5);
				transition: all .4s;
				i {
					opacity: 1;
					transition: all .4s;
				}
			}
			.img-hover {
				transition: all 0.5s ease;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0);
				i {
					transition: all 0.5s ease;
					font-size: 50px;
					color: #fff;
					opacity: 0;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
			}
			@media(max-width: 768px) {
				margin: 0 auto 30px;
			}
		}
	}
}

/* TESTIMONIALS / WHAT PEOPLE SAY */
.testimonials-section {
	background-color: #eee;
	padding: 100px 0;
	.section-title {
		margin-bottom: 70px;
		text-align: center;
	}
	.flexslider-tes {
		max-width: 700px;
		display: block;
		margin: auto;
		color: #fff;
		font-size: 17px;
		img.client {
			border-radius: 100%;
			margin: auto;
			display: block;
			margin-bottom: 30px;
			border: 1px solid #d8d8d8;
			box-shadow: 1px 0 6px rgba(0,0,0,.1);
		}
		.testimonial-txt {
			margin-bottom: 20px;
			color: #5b5b5b;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
		}
		.client {
			font-size: 14px;
			color: #626363;
			margin-bottom: 50px;
			text-align: center;
			font-weight: 700;
		}
		.flex-control-paging li a {
			width: 12px;
			height: 12px;
			background-color: transparent;
			border: 1px solid #333333;
			&:hover {
				background-color: #333333;	
			}
		}
		.flex-control-paging .flex-active {
			background-color: #333333;
		}
	}
	@media(max-width: 480px) {
		padding: 100px 30px;
	}
}

/* NEWSLETTER SECTION */
.newsletter {
	background-color: #0088a3;
	padding: 100px 0;
	h3.section-title {
		font-size: 25px;
		text-align: center;
		font-weight: 900;
		letter-spacing: 0.5px;
		color: #fff;
	}
	p {
		text-align: center;
		margin-bottom: 30px;
		color: #fff;
		font-size: 14px;
	}
	input {
		text-align: center;
		margin: auto;
		display: block;
		width: 350px;
		max-width: 100%;
		height: 40px;
		outline: none;
		border: none;
		background-color: #f2f2f2;
		font-size: 13px;
		font-family: $fontOne;
		color: #333333;
		&:hover {
			background-color: #fff;
		}
		&:focus {
			background-color: #fff;
		}
	}
	@media(max-width: 768px) {
		padding: 100px 30px;
	}
}

/* CONTACT US SECTION */
.contact {
	background-color: #fff;
	padding: 100px 0;
	.section-title {
		text-align: center;
		margin-bottom: 70px;
	}
	.container-sm {
		width: 90%;
		margin: auto;
		.option {
			position: relative;
			margin-bottom: 70px;
			i {
				position: absolute;
				left: 0;
				top: -5px;
				font-size: 50px;
				color: #333333;
			}
			p {
				font-size: 16px;
				color: #333333;
				font-family: $fontTwo;
				padding-left: 90px;
				line-height: 26px;
				@media(max-width: 380px) {
					padding-left: 60px;
				}
				@media(max-width: 320px) {
					padding-left: 50px;
					font-size: 14px;
				}
			}
		}
		input {
			display: block;
			outline: none;
			width: 100%;
			height: 54px;
			border: 1px solid #b2b2b2;
			margin-bottom: 30px;
			padding-left: 16px;
			color: #333333;
			font-family: $fontOne;
			font-size: 15px;
			font-weight: 300;
		}
		textarea {
			outline: none;
			width: 100%;
			height: 200px;
			border: 1px solid #b2b2b2;
			margin-bottom: 30px;
			padding-left: 16px;
			padding-top: 16px;
			color: #333333;
			font-family: $fontOne;
			font-size: 15px;
			font-weight: 300;
			resize: none;
		}
		/* PLACEHOLDER STYLES */
		::-webkit-input-placeholder {
		   color: #d6d6d6;
		   font-size: 14px;
		   font-family: $fontOne;
		}
		:-moz-placeholder {
		   color: #d6d6d6;
		   font-size: 14px;
		   font-family: $fontOne;
		}
		::-moz-placeholder {
		   color: #d6d6d6;
		   font-size: 14px;
		   font-family: $fontOne; 
		}
		:-ms-input-placeholder {  
		   color: #d6d6d6;
		   font-size: 14px;
		   font-family: $fontOne;  
		}
		.btn {
			float: right;
			&:hover {
				background-color: #333333;
			}
			@media(max-width: 768px) {
				float: none;
				margin: auto;
			}
		}
	}
}

/* GOOGLE MAP */
#map {
	height: 500px;
}
#map img {  
    max-width: none;   
} 

/* FOOTER */
footer {
	background-color: $blueColor;
	padding: 20px 0;
	p {
		float: left;
		color: #fff;
		font-size: 13px;
	}
	.social-media {
		float: right;
		a {
			color: #fff;
			margin-left: 16px;
			font-size: 14px;
			&:hover {
				color: #333333;
			}
		}
	}
	@media(max-width: 1200px) {
		padding: 20px 50px;
	}
	@media(max-width: 480px) {
		padding: 40px 20px;
		p {
			float: none;
			text-align: center;
			margin-bottom: 20px;
		}
		.social-media {
			float: none;
			text-align: center;
			a:first-child {
				margin-left: 0;
			}
		}
	}
}